// ./
import { PDFFooter, PDFHeader } from './PDFComponents'
import { PDFRectangle } from './PDFGeometry'
import { PDFTable } from './PDFTable'

// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Dependencies
import { GState, jsPDF, jsPDFOptions, TextOptionsLight } from 'jspdf'
import { Table } from 'jspdf-autotable'

// Class Export
export class PDF {
	private availableHeightSpace: number
	private currentPage = 1
	private defaultHeaderHeight = 24
	private defaultFooterHeight = 24
	private defaultFontFamily = 'OpenSans-Normal'
	private doc: jsPDF

	// Coordenadas de Posición.
	private gX = 0
	private gY = 0

	// Propiedades Generales.
	private options: jsPDFOptions = {
		format: 'letter',
		orientation: 'p',
		unit: 'pt'
	}

	// Propiedades Varias.
	private miscOptions: PDFMiscOptions = {
		drawFooterSafeArea: false,
		drawHeaderSafeArea: false,
		margin: 28
	}

	// Propiedades Textos.
	private textOptions: TextOptions = {
		align: 'left',
		baseline: 'top'
	}

	// Constructor
	constructor(options: jsPDFOptions = null, miscOptions: PDFMiscOptions = null) {
		const _options = options || {}
		this.options = { ...this.options, ..._options }

		const _miscOptions = miscOptions || {}
		this.miscOptions = { ...this.miscOptions, ..._miscOptions }
	}

	/* <=================|=============================|==================> */
	/* <=================| PRIVATE DECLARATION METHODS |==================> */
	/* <=================|=============================|==================> */

	private _getScaleFactor() {
		return this.doc.internal.scaleFactor
	}

	/* <==================|============================|==================> */
	/* <==================| PUBLIC DECLARATION METHODS |==================> */
	/* <==================|============================|==================> */

	public addImage(imagePath: string, ...args: [XPos, YPos, Width, Height, boolean?]) {
		// Propiedades Validas
		let w, h

		// Asignar los valores a las propiedades.
		switch (args.length) {
			case 5:
			case 4:
				this.updateGlobalXY(args[0], args[1])
				w = args[2]
				h = args[3]
				break
		}
		
		this.doc.addImage(new Image().src = imagePath, 'PNG', this.getGlobalX(), this.getGlobalY(), w, h)

		// Verificar si el ultimo argumento es booleano.
		const lastArg = args[args.length - 1]
		if (typeof lastArg === 'boolean' && lastArg === true) {
			this.updateGlobalY(h)
		}

		return this
	}

	public addImageCentered(imagePath: string, ...args: [YPos, Width, Height, boolean?]) {
		// Propiedades Validas
		let w, h

		// Asignar los valores a las propiedades.
		switch (args.length) {
			case 4:
			case 3:
				this.updateGlobalXY(0, args[0])
				w = args[1]
				h = args[2]
				break
		}

		const centeredX = (this.getPageWidth() - w) / 2
		this.doc.addImage(new Image().src = imagePath, 'PNG', centeredX, this.getGlobalY(), w, h)

		// Verificar si el ultimo argumento es booleano.
		const lastArg = args[args.length - 1]
		if (typeof lastArg === 'boolean' && lastArg === true) {
			this.updateGlobalY(h)
		}

		return this
	}

	public addNumberOfPages() {
		this.setFontColor('#555')
		this.setFontSize(8)
		this.updateTextOption('align', 'right')

		for (let pageNumber = 1; pageNumber <= this.getNumPages(); pageNumber++) {
			this.doc.setPage(pageNumber)
			this.doc.text(`Página ${ pageNumber } de ${ this.getNumPages() }`, this.getPageWidth() + this.miscOptions.margin, this.miscOptions.margin, this.textOptions)
		}
	}

	public addText(text: string, x = 0, y = 0, size = 0, includeTextHeight = false) {
		const textLines = this.doc.splitTextToSize(text, this.getPageWidth())
		const textHeight = includeTextHeight ? this.getTextHeight(textLines) + this.doc.getLineHeightFactor() : 0
		this.updateAvailableHeightSpace(this.getTextHeight(textLines))

		if (size > 0) this.setFontSize(size)
		this.updateGlobalXY(x, y)
		this.doc.text(textLines, this.getGlobalX(), this.getGlobalY(), this.textOptions)

		this.updateGlobalY(textHeight)
		return this
	}

	public addTextCentered(text: string, y = 0, size = 0, includeTextHeight = false) {
		const textLines = this.doc.splitTextToSize(text, this.getPageWidth())
		const textHeight = includeTextHeight ? this.getTextHeight(textLines) + this.doc.getLineHeightFactor() : 0
		this.updateAvailableHeightSpace(this.getTextHeight(textLines))

		if (size > 0) this.setFontSize(size)
		this.updateGlobalXY(0, y)
		this.doc.text(text, this.getCenteredPagePosForText(text), this.getGlobalY(), this.textOptions)

		this.updateGlobalY(textHeight)
		return this
	}

	public addTextRight(text: string, x = 0, y = 0, size = 0, includeTextHeight = false) {
		const textLines = this.doc.splitTextToSize(text, this.getPageWidth())
		const textHeight = includeTextHeight ? this.getTextHeight(textLines) + this.doc.getLineHeightFactor() : 0
		this.updateAvailableHeightSpace(this.getTextHeight(textLines))

		this.updateTextOption('align', 'right')
		if (size > 0) this.setFontSize(size)
		this.updateGlobalXY(x, y)
		this.doc.text(textLines, this.getPageWidth() + this.getPageMargin(), this.getGlobalY(), this.textOptions)
		this.updateTextOption('align', 'left')

		this.updateGlobalY(textHeight)
		return this
	}

	public create(headerText: string = null, logoPath: string = null, drawFirstPageHeader = false, drawFirstPageFooter = false) {
		this.doc = new jsPDF(this.options)
		this.doc.setPage(1)

		this.setFont(this.defaultFontFamily)
		this.availableHeightSpace = this.getPageHeight()

		return this
	}

	public forEveryPage(startFrom: number, callback: (self: PDF, pageIndex: number, isLastPage: boolean) => void) {
		const totalPages  = this.getNumPages()
		for (let i = startFrom; i <= totalPages; i++) {
			this.doc.setPage(i)
			callback(this, i, i === totalPages)
		}
	}

	public getAvailableHeightSpace() {
		return this.availableHeightSpace
	}

	public getCenteredPagePosForText(text: string) {
		const pageWidth = this.getPageWidth(false)
		const txtWidth  = this.getTextWidth(text)
		return ( pageWidth - txtWidth ) / 2
	}

	public getCurrentPage() {
		return this.currentPage
	}

	public getDefaultFooterHeight() {
		return this.defaultFooterHeight
	}

	public getDefaultHeaderHeight() {
		return this.defaultHeaderHeight
	}

	public getFontHeight() {
		return this.doc.getFontSize()
	}

	public getGlobalDoc() {
		return this.doc
	}

	public getGlobalX() {
		return this.gX + this.getPageMargin()
	}

	public getGlobalY() {
		return this.gY + this.getPageMargin()
	}

	public getLastTable() {
		const doc: any = this.doc
		return doc.lastAutoTable as Table
	}

	public getNumPages() {
		return (<any> this.doc.internal).getNumberOfPages()
	}

	public getLastTableY() {
		const lastTable = this.getLastTable()
		return lastTable.body[lastTable.body.length - 1].cells[0].y - 6
	}

	public getPageHeight(includePageMargin = true) {
		const pageHeight = this.doc.internal.pageSize.height
		const marginOffset = this.miscOptions.margin
		return pageHeight - (includePageMargin ? marginOffset * 2 : 0)
	}

	public getPageMargin() {
		return this.miscOptions.margin
	}

	public getPageWidth(includePageMargin = true) {
		const pageWidth = this.doc.internal.pageSize.width
		const marginOffset = this.miscOptions.margin
		return pageWidth - (includePageMargin ? marginOffset * 2 : 0)
	}

	public getTextHeight(text: string | Array<string>) {
		return this.getFontHeight() * (Array.isArray(text) ? text.length : 1)
	}

	public getTextOptions() {
		return this.textOptions
	}

	public getTextWidth(text: string | Array<string>) {
		if (Array.isArray(text)) {
			let maxWidth = 0
			for (const line of text) {
				const w = this.doc.getStringUnitWidth(line)
				if (w > maxWidth) maxWidth = w
			}
			return maxWidth * this.doc.getFontSize() / this._getScaleFactor()
		}
		else {
			return this.doc.getStringUnitWidth(text) * this.doc.getFontSize() / this._getScaleFactor()
		}
	}

	public open(fileName = 'generated') {
		this.doc.setProperties({ title: fileName })
		window.open(<any> this.doc.output('bloburl'))

		// const result = this.doc.output('blob')
		// let a = document.createElement("a") 
		// let blobURL = URL.createObjectURL(result)
		// a.download = 'test.pdf'
		// a.href = blobURL
		// a.click()
	}

	public save(fileName: string) {
		this.doc.save(`${ fileName }.pdf`)
		return this
	}

	public setAvailableHeightSpace(h: number) {
		this.availableHeightSpace = h
		return this
	}

	public setCurrentPage(p: number) {
		this.currentPage = p
		return this
	}

	public setFillColor(hex: string) {
		this.doc.setFillColor(hex)
		return this
	}

	public setFont(name: string, style?: string, weight?: number | string) {
		this.doc.setFont(name, style, weight)
		return this
	}

	public setFontColor(hex: string) {
		this.doc.setTextColor(hex)
		return this
	}

	public setFontSize(size: number) {
		this.doc.setFontSize(size)
		return this
	}

	public setGlobalY(y: number) {
		this.gY = y
		return this
	}

	public setStrokeColor(hex: string) {
		this.doc.setDrawColor(hex)
		return this
	}

	public setTempGStateProps(props: GState, callback: (self: PDF) => void) {
		this.doc.saveGraphicsState()
		this.doc.setGState(new GState(props))
		callback(this)
		this.doc.restoreGraphicsState()
	}

	public setTextOptions(options: TextOptions) {
		this.textOptions = options
		return this
	}

	public updateAvailableHeightSpace(v: number, preventNewPageAdded = false) {
		const newPageHeight = this.availableHeightSpace - v
		this.availableHeightSpace = newPageHeight
		DevelopmentTools.printWarn('[PDF]:updateAvailableHeightSpace() AvailableHeightSpace:', this.availableHeightSpace)
		
		if (this.availableHeightSpace < 0 && (!preventNewPageAdded)) {
			DevelopmentTools.printWarn('[PDF]:updateAvailableHeightSpace() New Page Added!')
			this.setGlobalY(16) // Page Number Offset
			this.availableHeightSpace = this.getPageHeight()
			this.doc.addPage()
			return true
		}
	}

	public updateGlobalX(x: number) {
		this.gX = x
	}

	public updateGlobalY(y: number) {
		this.gY += y
	}

	public updateGlobalXY(x: number, y: number) {
		this.updateGlobalX(x)
		this.updateGlobalY(y)
		return this
	}

	public updateTextOption(option: string, value: any) {
		this.textOptions[option] = value
		return this
	}
}

// Interface
export interface PDFMiscOptions {
	drawFooterSafeArea?: boolean
	drawHeaderSafeArea?: boolean
	margin?: number
}

// Variable Types
type Height = number
type TextOptions = TextOptionsLight & { [key: string]: string }
type XPos = number
type YPos = number
type Width = number