// Classes
import { AlgorithmTools }   from '@/Classes/Static/AlgorithmTools'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Server }    from '@/Constants/Global/Server'
import { VueRouter } from '@/Constants/Global/VueRouter'
import { Vuex }      from '@/Constants/Global/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const SidePanel = VueMixins(MixinComponent, MixinFetch, MixinResponsive).extend({
	name: 'SidePanel',

	props: {
		defaultItem: String
	},

	data: function() {
		return {
			states: {
				company: AppValues.Strings.DEFAULT_EMPTY_STRING,
				isMobile: false,
				numActives: 0,
				searchStorages: '',
				selected: undefined,
				storages: [],
				technical: AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		}
	},

	created: function() {
		this._resolveDefaultItemFromProp()
	},

	mounted: function() {
		this._fetchUserCheckListData()
	},

	computed: {
		_getStorages: function(): any {
			const { searchStorages, storages } = this.states
			if (searchStorages.trim() === '') return storages
			return storages.filter((x: any) => {
				const name = x.name || ''
				const code = x.code || ''
				const bName = AlgorithmTools.KMPSearch(searchStorages?.toLowerCase(), name.toLowerCase()) !== -1
				const bCode = AlgorithmTools.KMPSearch(searchStorages?.toLowerCase(), code.toLowerCase()) !== -1
				return bName || bCode
			})
		}
	},

	methods: {
		_fetchUserCheckListData: async function() {
			// Realizar la Petición al servidor.
			const { _idUser } = Store.getters.getStoredUser
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetCheckListsStoragesByUser, params: { _idUser } })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Almacenar la Información requerida para el Modulo de CheckList.
				const data = response.data.body[0]
				const storages = (data.storages || []) as Array<any>

				// Asignar la Ubicación por Defecto si existe al menos una.
				if (storages?.length > 0) {
					const sorted = storages.sort((a, b) => a.code.toUpperCase() > b.code.toUpperCase() ? 1 : -1)
					this.setStates<SidePanelRef['states']>({ selected: sorted[0], storages: sorted })
					this._refreshItemsAndUpdateSelection(sorted[0])
                    this.$emit('onSPItemClick', sorted[0])
				}
			}
		},

		_getSelectedItemClass: function(item: any) {
			return { selected: this.states.selected?.code === item.code }
		},

		_refreshItemsAndUpdateSelection: function(item: any) {
			this.setStates<SidePanelRef['states']>({ selected: item })
			this._updateCompanyName()
			this._updateTechnicalName()
		},

		_resolveDefaultItemFromProp: function() {
			const index = parseInt(this.$props.defaultItem)
			if (!isNaN(index)) {
				const item = this.states.storages[index]
				this.setStates<SidePanelRef['states']>({ selected: item })
			}
		},

		_updateCompanyName: function() {
			const { selected } = this.states
			this.setStates<SidePanelRef['states']>({ company: selected.companyStorageName || AppValues.Strings.DEFAULT_EMPTY_STRING })
		},

		_updateTechnicalName: function() {
			const { selected } = this.states
			this.setStates<SidePanelRef['states']>({ technical: selected?.technicalName || AppValues.Strings.DEFAULT_EMPTY_STRING })
		},

		onSPItemClick: function(item: any, index: number) {
			DevelopmentTools.printWarn('[SidePanel]:onSPItemClick() event triggered', index)
			this._refreshItemsAndUpdateSelection(item)
			this.$emit('onSPItemClick', item)
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Companies, '_initCompanyName'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Storages, ['_initCompanyName', '_initSidePanel', '_initTechnicalName']),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Users, '_initTechnicalName')
	}
})

// Exports
export default SidePanel
export type SidePanelRef = InstanceType<typeof SidePanel>