// Classes
import { PDF } from '@/Classes/Files/pdf/PDF'
import { PDFFooter, PDFHeader } from '@/Classes/Files/pdf/PDFComponents'
import { PDFRoundedRectangle } from '@/Classes/Files/pdf/PDFGeometry'
import { PDFTable } from '@/Classes/Files/pdf/PDFTable'

function _getGeneralInfoColumns() {
	return [
		{ key: 'col', label: 'KEY' },
		{ key: 'val', label: 'VALUE' }
	]
}

export class PDFUtils {
	// public addFooterToPages(pdf: PDF, pageIndex: number, pdfTable?: PDFTable) {
	// 	pdf.forEveryPage(pageIndex, (self, _, isLastPage) => {
	// 		self.setGlobalY(0)
	// 		new PDFFooter(self, false)
	// 		if (isLastPage && pdfTable) self.setGlobalY(self.getLastTableY())
	// 	})
	// 	return this
	// }

	// public addHeaderToPages(pdf: PDF, pageIndex: number, headerText: string, headerLogo: string, pdfTable?: PDFTable) {
	// 	pdf.forEveryPage(pageIndex, (self, _, isLastPage) => {
	// 		self.setGlobalY(0)
	// 		new PDFHeader(self, headerText, headerLogo)
	// 		if (isLastPage && pdfTable) self.setGlobalY(self.getLastTableY())
	// 	})
	// 	return this
	// }

	public addSubHeader(pdf: PDF, text: string, includeHeight = true) {
		new PDFRoundedRectangle(pdf, -pdf.getPageMargin(), 24, 156, 32, 32, [0, 1, 1, 0], '#FF8225').setIncludeHeight(false).draw()
		pdf.setFontColor('#4C78B3').setFontSize(24)
		pdf.addTextCentered(text, 4, 0, includeHeight)
		return this
	}

	public addSubHeaderAndInfoToRight(pdf: PDF, text: string, ...textLines: Array<string>) {
		this.addSubHeader(pdf, text, false)
		pdf.setFontColor('#333')
		let linesSumY = 0
		for (let i = 0; i < textLines.length; i++) {
			const lineY = 14 * i
			pdf.addTextRight(textLines[i], 0, lineY, 10, false)
			linesSumY += lineY
		}
		pdf.updateGlobalY(linesSumY)
		return this
	}

	public addTableFooter(pdfTable: PDFTable, extraY: number, text: string) {
		const extraOffsetY = pdfTable.getPDF().getNumPages() > 2 ? extraY : 0
		pdfTable.setFooterY(extraOffsetY).drawFooter(pdfTable.getPDF(), text)
		return this
	}

	public addTableClientInformation(pdf: PDF, client: string, storage: string) {
		const generalInfoRowsTable1 = [
			{ col: 'Cliente:', val: client },
			{ col: 'Ubicación:', val: storage }
		]
		new PDFTable(pdf, 0, 8, _getGeneralInfoColumns(), generalInfoRowsTable1)
			.setActiveColId('col').colFontStyle('bold').colTextColor('#FD921A')
			.setTheme('grid')
			.setUpdateGlobalY(false)
			.setWidth(pdf.getPageWidth() / 2)
			.showHeader(false)
			.draw()
		;
		return this
	}
	
	public addTableDateResponsibleInformation(pdf: PDF, date: string, responsible: string) {
		const generalInfoRowsTable2 = [
			{ col: 'Fecha Informe:', val: date },
			{ col: 'Responsable:', val: `${ responsible }` }
		]
		new PDFTable(pdf, pdf.getPageWidth() / 2, 8, _getGeneralInfoColumns(), generalInfoRowsTable2)
			.setActiveColId('col').colFontStyle('bold').colTextColor('#FD921A')
			.setTheme('grid')
			.setWidth(pdf.getPageWidth() / 2)
			.showHeader(false)
			.draw()
		;
		return this
	}
}