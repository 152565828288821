// Class Export
export class ChartUtils {
	public static createQuickProps(numProps: number) {
		const obj: any = {}
		for (let i = 1; i <= numProps; i++) obj[`chart${ i }`] = {}
		return obj
	}

	public createCustomProps(numProps: number, data: any) {
		const props = ChartUtils.createQuickProps(numProps)
		for (let i = 1; i <= numProps; i++) {
			const chartProp = props[`chart${ i }`]
			if (!chartProp.params) chartProp.params = {}
			chartProp.params.title = data.titles[i - 1]
		}
		return props
	}
}