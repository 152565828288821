// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components
import CompanyLogo from '@/Components/Extras/CompanyLogo/template.vue'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const AttributesList = VueMixins(MixinComponent, MixinResponsive).extend({
	name: 'AttributesList',

	components: {
		CompanyLogo
	},

	props: {
		show: Boolean
	},

	data: function() {
		return {
			states: {
				data: undefined,
				type: 'actives',
				options: []
			}
		}
	},

	methods: {
		_isActives: function() {
			return this.states.type === 'actives'
		},

		onALCloseClick: function() {
			DevelopmentTools.printWarn('[AttributesList]:onALCloseClick() event triggered')
			this.$emit('onALCloseClick')
		}
	}
})

// Exports
export default AttributesList
export type AttributesListRef = InstanceType<typeof AttributesList>