// ./
import { PDF } from './PDF'
import { PDFImage, PDFText } from './PDFElements'
import { PDFRectangle, PDFRoundedRectangle } from './PDFGeometry'

// Classes
import { Vector4 } from '@/Classes/Vectors/Vector4'

// Class Export
export class PDFContainer extends Vector4 {
	private autoSize: boolean
	private currentOffsetPosition = 0
	private drawSafeArea: boolean
	private elements: Array<ContainerElementTypes> = []
	private offsetY = 0
	private orientation: 'H' | 'V' = 'V'
	private padding = 0
	private pdf: PDF
	private wrapText = true

	// Propiedades de estilo del Container.
	private containerStyles: IContainerStyles = {
		backgroundColor: 'none',
		borderColor: 'none'
	}

	// Constructor.
	constructor(pdf: PDF, x: number, y: number, w: ContainerWidthTypes, h: ContainerHeightTypes, drawSafeArea = false) {
		super(x, y, 0, 0)
		this.pdf = pdf
		this.offsetY = y
		this.drawSafeArea = drawSafeArea
		this._calcWidthHeight(w, h)
	}

	/* <=================|=============================|==================> */
	/* <=================| PRIVATE DECLARATION METHODS |==================> */
	/* <=================|=============================|==================> */

	private _calcShapeOffsetForAlign(e: PDFRectangle, axis: string, fallbackPos: number, containerPos: number) {
		let offset = 0

		if (this._isHorizontal()) {
			if (axis === 'y') {
				switch (e.getAlign()) {
					case 'center':
						offset = (this.getW() - e.getW()) / 2
						break
					case 'bottom':
						offset = this.getW() - e.getW()
						break
				}
			}
		}
		else {
			if (axis === 'x') {
				switch (e.getAlign()) {
					case 'center':
						offset = (this.getZ() - e.getZ()) / 2
						break
					case 'right':
						offset = this.getZ() - e.getZ()
						break
				}
			}
		}

		return offset
	}

	private _calcTextOffsetsForAlign(e: PDFText, axis: string) {
		let offsetForSafeArea = 0, offsetForText = 0

		if (this._isHorizontal()) {
			if (axis === 'y') {
				this.pdf.updateTextOption('align', 'left')
				switch (e.getAlign()) {
					case 'top':
						offsetForText = 0
						break
					case 'center':
						offsetForText = this._getCenterYPosForText(e.getText())
						break
					case 'bottom':
						offsetForText = this.getW() - this.pdf.getTextHeight(e.getText())
						break
				}
			}
		}
		else {
			if (axis === 'x') {
				switch (e.getAlign()) {
					case 'left':
						this.pdf.updateTextOption('align', 'left')
						offsetForText = 0
						break
					case 'center':
						this.pdf.updateTextOption('align', 'left')
						offsetForText = this._getCenterXPosForText(e.getText())
						break
					case 'right':
						this.pdf.updateTextOption('align', 'right')
						offsetForText = this.getZ()
						offsetForSafeArea = this.pdf.getTextWidth(this.pdf.getGlobalDoc().splitTextToSize(e.getText(), this.getZ()))
						break
				}
			}
		}

		return { offsetForSafeArea, offsetForText }
	}

	private _calcWidthHeight(w: ContainerWidthTypes, h: ContainerHeightTypes) {
		// Calcular el Ancho del Container.
		switch (w) {
			case 'F':
				this.setZ(this.pdf.getPageWidth(true) - this.getX())
				break
			default:
				this.setZ(w)
		}

		// Calcular el Alto del Container.
		switch (h) {
			case 'A':
				this.autoSize = true
				this.setW(0)
				break
			case 'F':
				this.setW(this.pdf.getPageHeight(true))
				break
			default:
				this.setW(h)
		}
	}

	private _drawContainerStyles(x: number, y: number, w: number, h: number) {
		const doc = this.pdf.getGlobalDoc()
		const styles = this.containerStyles

		// ContainerStyles.
		const backgroundColor = styles.backgroundColor !== 'none' ? styles.backgroundColor : 'white'
		const borderColor = styles.borderColor !== 'none' ? styles.borderColor : 'white'

		// Aplicar los cambios visuales y dibujar el rectangulo.
		this.pdf.setFillColor(backgroundColor)
		this.pdf.setStrokeColor(borderColor)
		doc.rect(x, y, w, h, 'FD')
	}

	private _drawSafeArea(x: number, y: number, w: number, h: number) {
		if (this.drawSafeArea) {
			this.pdf.setStrokeColor('#FF00FF')
			this.pdf.getGlobalDoc().rect(x, y, w, h)
		}
	}

	private _getCenterXPosForText(text: string | Array<string>) {
		const width = this.getZ()
		const txtWidth  = this.pdf.getTextWidth(text)
		return (width - txtWidth) / 2
	}

	private _getCenterYPosForText(text: string | Array<string>) {
		const height = this.getW()
		const txtHeight  = this.pdf.getTextHeight(text)
		return (height - txtHeight) / 2
	}

	private _getTextHeight(e: PDFText) {
		const linesOfText = this.pdf.getGlobalDoc().splitTextToSize(e.getText(), this.getZ())
		const textHeight = this.pdf.getTextHeight(linesOfText)
		return textHeight
	}

	private _isHorizontal() {
		return this.orientation === 'H'
	}

	private _renderImage(e: PDFImage, containerX: number, containerY: number, containerW: number, containerH: number) {
		// Determinar los Offsets segun la Orientación.
		const oX = this._isHorizontal() ? this.getCurrentOffsetPosition() : e.getOffset()
		const oY = !this._isHorizontal() ? this.getCurrentOffsetPosition() : e.getOffset()

		const eX = oX + containerX
		const eY = oY + containerY
		const _w = e.getWidth()
		const _h = e.getHeight()

		// Dibujar solamente los elementos que entren en el espacio del Container.
		const boundary = this._isHorizontal() ? (eX + _w <= containerX + containerW) : (eY + _h <= containerY + containerH)
		if (boundary) {
			this._drawSafeArea(eX, eY, _w, _h)
			this.pdf.getGlobalDoc().addImage(new Image().src = e.getSource(), 'PNG', eX, eY, _w, _h)

			// Actualizar el espacio usado por el elemento.
			this.updateCurrentOffsetPosition(this._isHorizontal() ? e.getWidth() : e.getHeight())
		}
	}

	private _renderRectangle(e: PDFRectangle | PDFRoundedRectangle, containerX: number, containerY: number, containerW: number, containerH: number) {
		// Determinar los Offsets segun la Orientación.
		const oX = this._isHorizontal() ? this.getCurrentOffsetPosition() : e.getX()
		const oY = !this._isHorizontal() ? this.getCurrentOffsetPosition() : e.getX()

		// Posiciones X/Y.
		let eX = oX + containerX
		let eY = oY + containerY

		// Dimensión de la forma.
		const _w = e.getZ()
		const _h = e.getW()

		// Determinar si las posiciones X/Y deben ser actualizadas segun la orientación.
		const aOffsetX = this._calcShapeOffsetForAlign(e, 'x', eX, containerX)
		const aOffsetY = this._calcShapeOffsetForAlign(e, 'y', eY, containerY)
		
		// Ajuster el offset según la Orientación.
		if (this._isHorizontal()) {
			eY += aOffsetY
		}
		else {
			eX += aOffsetX
		}

		// Dibujar solamente los elementos que entren en el espacio del Container.
		const boundary = this._isHorizontal() ? (eX + _w <= containerX + containerW) : (eY + _h <= containerY + containerH)
		if (boundary) {
			// Espacio extra solo para el 'safeArea'.
			const _safeAreaGapW = this._isHorizontal() ? e.getGap() : 0
			const _safeAreaGapH = !this._isHorizontal() ? e.getGap() : 0

			// Renderizar la forma en el documento.
			this._drawSafeArea(eX, eY, _w + _safeAreaGapW, _h + _safeAreaGapH)
			this.pdf.setFillColor(e.getColor())
			this.pdf.setStrokeColor(e.getStrokeColor() || e.getColor())
			e.draw(eX, eY, _w, _h)

			// Actualizar el espacio usado por el elemento.
			const offset = this._isHorizontal() ? e.getZ() : e.getW()
			this.updateCurrentOffsetPosition(offset + e.getGap())
		}
	}

	private _renderText(e: PDFText, containerX: number, containerY: number, containerW: number, containerH: number) {
		// Determinar los Offsets segun la Orientación.
		const oX = this._isHorizontal() ? this.getCurrentOffsetPosition() : e.getOffset()
		const oY = !this._isHorizontal() ? this.getCurrentOffsetPosition() : e.getOffset()
		
		// Posiciones X/Y.
		let eX = oX + containerX
		let eY = oY + containerY

		// Aplicar la Fuente de esta instancia de 'PDFText'.
		const { font, style } = e.getFont()
		this.pdf.setFont(font, style)
		
		// Determinar si las posiciones X/Y deben ser actualizadas segun la orientación.
		const tempAlign = this.pdf.getTextOptions().align
		if (e.getFontSize() > 0) this.pdf.setFontSize(e.getFontSize())
		const aOffsetX = this._calcTextOffsetsForAlign(e, 'x')
		const aOffsetY = this._calcTextOffsetsForAlign(e, 'y')
		
		// Ajuster el offset según la Orientación.
		if (this._isHorizontal()) {
			eY += aOffsetY.offsetForText
		}
		else {
			eX += aOffsetX.offsetForText
		}
		
		const linesOfText = this.pdf.getGlobalDoc().splitTextToSize(e.getText(), containerW)
		const textWidth = this.pdf.getTextWidth(linesOfText)
		const textHeight = this.pdf.getTextHeight(linesOfText)

		// Dibujar solamente los elementos que entren en el espacio del Container.
		const boundary = this._isHorizontal() ? (eX + textWidth <= containerX + containerW) : (eY + textHeight <= containerY + containerH)
		if (boundary) {
			// Espacio extra solo para el 'safeArea'.
			const _safeAreaGapW = this._isHorizontal() ? e.getGap() : 0
			const _safeAreaGapH = !this._isHorizontal() ? e.getGap() : 0

			// Renderizar el texto en el documento.
			this._drawSafeArea(eX - aOffsetX.offsetForSafeArea, eY - aOffsetY.offsetForSafeArea, textWidth + _safeAreaGapW, textHeight + _safeAreaGapH)
			this.pdf.setFontColor(e.getColor())
			this.pdf.getGlobalDoc().text(linesOfText, eX, eY, this.pdf.getTextOptions())
			this.pdf.updateTextOption('align', tempAlign)

			// Actualizar el espacio usado por el elemento.
			const offset = this._isHorizontal() ? textWidth : textHeight
			this.updateCurrentOffsetPosition(offset + e.getGap())
		}
	}

	private _updateAutoHeight(e: ContainerElementTypes) {
		// Realizar los calculos cuando 'autoSize' está activado.
		if (this.autoSize) {
			if (this._isHorizontal()) {
				// Si la orientación es 'Horizontal', el alto del Container debe estar determinado por el elemento con la alturá mas alta.
				let highestHeight = this.getW()
				if (e instanceof PDFImage) {
					if (e.getHeight() > highestHeight) highestHeight = e.getHeight() + e.getGap()
				}
				else if (e instanceof PDFRectangle || e instanceof PDFRoundedRectangle) {
					if (e.getW() > highestHeight) highestHeight = e.getW() + e.getGap()
				}
				else if (e instanceof PDFText) {
					if (e.getFontSize() > 0) this.pdf.setFontSize(e.getFontSize())
					if (this._getTextHeight(e) > highestHeight) highestHeight = this._getTextHeight(e) + e.getGap()
				}
				this.setW(highestHeight + this.padding)
			}
			else {
				// Obtener la altura actual del Container.
				const currentHeight = this.getW()
				let newHeight = currentHeight

				// Obtener la altura correspondiente a cada tipo de elemento.
				if (e instanceof PDFImage) {
					newHeight += e.getHeight() + e.getGap()
				}
				else if (e instanceof PDFRectangle || e instanceof PDFRoundedRectangle) {
					newHeight += e.getW() + e.getGap()
				}
				else if (e instanceof PDFText) {
					if (e.getFontSize() > 0) this.pdf.setFontSize(e.getFontSize())
					newHeight += this._getTextHeight(e) + e.getGap()
				}

				// Finalmente, actualizar la altura.
				this.setW(newHeight + this.padding)
			}
		}
	}

	/* <==================|============================|==================> */
	/* <==================| PUBLIC DECLARATION METHODS |==================> */
	/* <==================|============================|==================> */

	public addElement(e: ContainerElementTypes) {
		this.elements.push(e)
		this._updateAutoHeight(e)
		return this
	}

	public draw(updateGlobalY = true) {
		// Coordenadas y Tamaños.
		const coords = {
			x: this.getX() + this.pdf.getGlobalX(),
			y: this.getY() + this.pdf.getGlobalY(),
			w: this.getZ(),
			h: this.getW()
		}

		// Restar el espacio utilizado por el Container.
		const newPageAdded = this.pdf.updateAvailableHeightSpace(coords.h + this.offsetY)
		if (newPageAdded) coords.y = this.pdf.getGlobalY()

		// Es posible que los contenedores terminen por consumir todo el espacio Y disponible.
		// Si es así, verificar que quede espacio antes de renderizar el contenedor.
		if (this.pdf.getAvailableHeightSpace() >= 0) {
			const doc = this.pdf.getGlobalDoc()
			
			// Incluir el Area Segura del Container.
			if (this.mustDrawSafeArea()) {
				this.pdf.setStrokeColor('#FF00FF')
				doc.rect(coords.x, coords.y, coords.w, coords.h, 'S')
			}

			// Dibujar el aspecto visual del Container.
			this._drawContainerStyles(coords.x, coords.y, coords.w, coords.h)

			// Renderizar los elementos de cada Container.
			for (const e of this.getElements()) {
				const _x = coords.x + this.padding
				const _y = coords.y + this.padding

				if (e instanceof PDFRectangle || e instanceof PDFRoundedRectangle) {
					this._renderRectangle(e, coords.x, coords.y, coords.w, coords.h)
				}
				if (e instanceof PDFImage) {
					this._renderImage(e, coords.x, coords.y, coords.w, coords.h)
				}
				else if (e instanceof PDFText) {
					this._renderText(e, _x, _y, coords.w, coords.h)
				}
			}

			// verificar si la posición Y global será actualizada.
			if (updateGlobalY) this.pdf.updateGlobalY(this.getY() + this.getW())
		}
		return this
	}

	public getCurrentOffsetPosition() {
		return this.currentOffsetPosition
	}

	public getElements() {
		return this.elements
	}

	public mustDrawSafeArea() {
		return this.drawSafeArea
	}

	public setBackgroundColor(c: string) {
		this.containerStyles.backgroundColor = c
		return this
	}

	public setBorderColor(c: string) {
		this.containerStyles.borderColor = c
		return this
	}

	public setOrientation(o: 'H' | 'V') {
		this.orientation = o
		return this
	}

	public setPadding(n: number) {
		this.padding = n
		return this
	}

	public updateCurrentOffsetPosition(o: number) {
		this.currentOffsetPosition += o
		return this
	}
}

interface IContainerStyles {
	backgroundColor: string
	borderColor: string
}

type ContainerElementTypes = PDFImage | PDFRectangle | PDFRoundedRectangle | PDFText
type ContainerHeightTypes = number | 'A' | 'F'
type ContainerWidthTypes = number | 'F'