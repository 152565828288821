// ./
import { PDF } from './PDF'
import { PDFRectangle } from './PDFGeometry'

// PDFComponent
class PDFComponent {
	private height: number

	constructor(pdf: PDF, x: number, y: number, h: number, drawSafeArea: boolean) {
		this.height = h

		// SafeArea.
		if (drawSafeArea) {
			pdf.setTempGStateProps({ opacity: 0 }, (self) => {
				self.setGlobalY(x)
				new PDFRectangle(self, x, y, self.getPageWidth(), this.getHeight())
					.setIncludeHeight(false)
					.setStrokeColor('#FF00FF')
					.draw()
				;
			})
		}
	}

	public getHeight() {
		return this.height
	}
}

// PDFHeader Class Export
export class PDFHeader extends PDFComponent {
	constructor(pdf: PDF, headerText: string, logoPath: string, drawSafeArea?: boolean) {
		super(pdf, 0, 0, pdf.getDefaultHeaderHeight(), drawSafeArea)

		pdf.setFontColor('black')
		pdf.setFontSize(16)
		pdf.updateTextOption('align', 'left')
		pdf.addImage(logoPath, 0, 0, 120, this.getHeight())
		pdf.addTextCentered(headerText, 4, 0, true)
		pdf.setGlobalY(this.getHeight())
	}
}

// PDFFooter Class Export
export class PDFFooter extends PDFComponent {
	constructor(pdf: PDF, companyName: string, companyAddress: string) {
		const height = pdf.getDefaultFooterHeight()
		const footerY = pdf.getPageHeight() - height
		super(pdf, 0, footerY, height, false)

		// Comprobar si existe espacio para el Footer.
		pdf.updateAvailableHeightSpace(height)
		pdf.setGlobalY(footerY + 4)
		new PDFRectangle(pdf, 0, 0, pdf.getPageWidth(), 4, '#FD921A').draw()
		pdf.addText(companyName, 0, 4, 10, false)
		pdf.addTextCentered(companyAddress, 0, 10)
	}
}