// ./
import { getChartGeneratorData, getChartGeneratorDataTable } from './charts.definition'
import { generatePDF } from './pdf.document'
import { parseAddonsResponse, parseCheckListsResponse, parseEquipmentsResponse } from './response.parser'
import { parseStackedAddonsResponse, parseStackedCheckListsResponse, parseStackedEquipmentsResponse } from './response.parser'

// Classes
import { ChartData, ChartOptions } from '@/Classes/Charts/ChartDefinition'
import { ChartLabel }       from '@/Classes/Charts/ChartLabel'
import { ChartUtils }       from '@/Classes/Charts/ChartUtils'
import { PDFTableColumn }   from '@/Classes/Files/pdf/PDFTable'
import { JoiManager }       from '@/Classes/Network/JoiManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { PrimitiveTools }   from '@/Classes/Static/PrimitiveTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Components (.vue)
import ChartBasic     from '@/Components/Extras/ChartBasic/template.vue'
import BasicHeader    from '@/Components/Global/BasicHeader/template.vue'
import DataTable      from '@/Components/Global/DataTable/template.vue'
import MenuBar        from '@/Components/Global/MenuBar/template.vue'
import AttributesList from '@/Components/Modules/4/AttributesList/template.vue'
import AuditCodeForm  from '@/Components/Modules/4/AuditCodeForm/template.vue'
import SidePanel      from '@/Components/Modules/4/SidePanel/template.vue'

// Component (Refs)
import { ChartBasicRef }     from '@/Components/Extras/ChartBasic/component'
import { BasicHeaderRef }    from '@/Components/Global/BasicHeader/component'
import { DataTableRef }      from '@/Components/Global/DataTable/component'
import { MenuBarRef }        from '@/Components/Global/MenuBar/component'
import { AttributesListRef } from '@/Components/Modules/4/AttributesList/component'
import { AuditCodeFormRef }  from '@/Components/Modules/4/AuditCodeForm/component'
import { SidePanelRef }      from '@/Components/Modules/4/SidePanel/component'

// Constants
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Server }      from '@/Constants/Global/Server'
import { VueRouter }   from '@/Constants/Global/VueRouter'
import { Vuex }        from '@/Constants/Global/Vuex'
import { Module4  }    from '@/Constants/Modules/Module4'

// Dependencies
import { Socket } from 'socket.io-client'
import VueMixins  from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View40 = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Modules.View40.NAME,

	components: {
		AttributesList,
		AuditCodeForm,
		BasicHeader,
		ChartBasic,
		DataTable,
		MenuBar,
		SidePanel
	},

	data: function() {
		return {
			charts: {
				params: {
					order: [7, 4, 6, 1, 3]
				},
				...new ChartUtils().createCustomProps(7, {
					titles: [
						'Cant. Preguntas x Día' , '% Preguntas x Día' , '% Preguntas (Promedio)',
						'Cant. CheckLists x Día', '% CheckLists x Día', '% CheckLists (Promedio)'
					]
				})
			},
			states: {
				chartFilter: null,
				chartPieDivision: '2',
				dataTableParser: parseEquipmentsResponse,
				numActivesPerStorage: {},
				settingTitleText: '',
				showAttributesList: false,
				showAuditCodeForm: false,
				showDataTable: true,
				showKPICharts: false,
				showKPIOperators: false,
				showKPIOptions: false,
				showKPIProgressIndicator: false
			}
		}
	},

	created: function() {
		this._initChartsConfig()
	},

	mounted: function() {
		this._checkSystemAccess()
		this._initComponents()
		this._initPermissions()
		this._initSocketEvents()
		this._initDataTableChartsConfig()

		// Establecer los elementos del componente 'MenuBar'.
		this._menuBar.setStates<MenuBarRef['states']>({ selected: Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES })

		// Establecer el valor por defecto para los Filtros por Periodo.
		this.setStates<View40Ref['states']>({ chartFilter: this._getChartsFilterOptions[0].value })

		this._dataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true })
	},

	beforeDestroy: function() {
		// Conexión del Cliente del SocketIO.
		const socket: Socket = Store.getters.getStoredSocket

		// Asegurar que los eventos dejen de estar activos.
		socket.off(Module4.M40.Socket.Events.CREATED)
	},

	computed: {
		_attributesList: function(): AttributesListRef {
			return this.$refs.attributesList as AttributesListRef
		},

		_auditCodeForm: function(): AuditCodeFormRef {
			return this.$refs.auditCodeForm as AuditCodeFormRef
		},

		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_dataTableChart1: function(): DataTableRef {
			return this.$refs.dataTableChart1 as DataTableRef
		},

		_getChartsFilterOptions: function() {
			return [
				{ text: 'Mes Actual', value: {
					group: Module4.M40.Defaults.ChartGroupPeriods.IN_DAYS,
					period: Module4.M40.Defaults.ChartPeriodFilters.CURRENT_MONTH }
				},
				{ text: 'Ultimos 15 días', value: {
					group: Module4.M40.Defaults.ChartGroupPeriods.IN_DAYS,
					period: Module4.M40.Defaults.ChartPeriodFilters.LAST_15_DAYS }
				},
				{ text: 'Ultimos 30 días', value: {
					group: Module4.M40.Defaults.ChartGroupPeriods.IN_DAYS,
					period: Module4.M40.Defaults.ChartPeriodFilters.LAST_30_DAYS }
				},
				{ text: 'Ultimos 12 meses', value: {
					group: Module4.M40.Defaults.ChartGroupPeriods.IN_MONTHS,
					period: Module4.M40.Defaults.ChartPeriodFilters.LAST_12_MONTHS }
				},
				{ text: 'Año Actual', value: {
					group: Module4.M40.Defaults.ChartGroupPeriods.IN_MONTHS,
					period: Module4.M40.Defaults.ChartPeriodFilters.CURRENT_YEAR }
				}
			]
		},

		_menuBar: function(): MenuBarRef {
			return this.$refs.menuBar as MenuBarRef
		},

		_sidePanel: function(): SidePanelRef {
			return this.$refs.sidePanel as SidePanelRef
		}
	},

	methods: {
		_checkSystemAccess: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			if ('ACCESS_MODULE_40' in userPermissions) {
				const permission = userPermissions.ACCESS_MODULE_40?.privileges.read
				if (!permission) this.$router.push({ name: VueRouter.Modules.View10.NAME, params: { unauthorizedRedirect: 'true' } })
			}
		},

		_fetchAddons: async function(_idStorage: ObjectId) {
			const user = Store.getters.getStoredUser
			if (user) {
				// Realizar la petición al Servidor.
				const _idAdminCompany = user._idAdminCompany
				const params = { _idAdminCompany, _idStorage }
				const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetCheckListsAddonsByIdStorage, params })

				// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
				if (response.status === Server.Response.StatusCodes.SUCCESS) {
					return response.data.body
				}
			}
		},

		_fetchCheckLists: async function(_idStorage: ObjectId, page: number) {
			// Realizar la petición al Servidor.
			const params = { _idStorage, itemsPerPage: this._dataTable.itemsPerPage, page }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetCheckListsByIdStorage, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}
		},

		_fetchEquipments: async function(_idStorage: ObjectId, page: number) {
			// Realizar la petición al Servidor.
			const params = { _idStorage, itemsPerPage: this._dataTable.itemsPerPage, page }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetActiveCheckListsForStorageByPage, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}
		},
		
		_fetchKPIs: async function(_idStorage: ObjectId, filterPeriod: Module4.M40.Defaults.ChartPeriodFilters) {
			// Realizar la petición al Servidor.
			const params = { _idStorage, filterNum: filterPeriod }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetCheckListsByFilter, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body
			}
		},

		_fetchOperators: function() {
			const { selected } = this._sidePanel.states
			if (selected) {
				const _idStorages = [selected._id]
				Store.dispatch('fetchAllStoragesOperators', { _idStorages, page: 1, itemsPerPage: 30 }).catch((error: any) => DevelopmentTools.printError(error.response))
			}
		},

		_getChart: function(index: number) {
			return this.charts[`chart${ index }`]
		},

		_getChartBasic: function(index: number) {
			return this.$refs[`chartBasic${ index }`] as ChartBasicRef
		},

		_initChartsConfig: function() {
			for (const chartIndex of this.charts.params.order) {
				const chart = this.charts[`chart${ chartIndex }`]
				chart.chartData = new ChartData<number>()
	
				// Instancia del objeto 'ChartLabel'.
				const chartLabel = new ChartLabel()
				.setFontFamily('Source Sans Pro')
				.setFontSize(12)
				.setFontWeight('bold')
	
				// Filtro de parametros para el 'ChartLabel' segun grafico.
				switch (chartIndex) {
					case 1: case 2: case 3: case 4: case 5: case 6:
						chartLabel
						.setAlign('top')
						.setAnchor('center')
						.setPadding('bottom', -24)
						break
					case 7:
						chartLabel
						.setAlign('right')
						.setAnchor('end')
						.setOffset(0)
						break
				}

				// Filtro de parametros para el 'ChartLabel' segun grafico.
				switch (chartIndex) {
					case 2: case 3: case 5: case 6: case 7:
						chartLabel.setFormatter((value: number) => `${ value }%`)
				}

				// Filtro de parametros para el 'ChartLabel' segun grafico.
				switch (chartIndex) {
					case 3: case 6:
						chartLabel.setColor('#FFF')
						break
					default:
						chartLabel.setColor('#333')
				}
	
				// Instancia del objeto 'ChartOptions'
				const options = new ChartOptions()
				.setMaintainAspectRatio(false)
				.setPlugin('datalabels', chartLabel)

				// Filtro de parametros para el 'ChartOptions' segun grafico.
				switch (chartIndex) {
					case 3: case 6:
						options
						.setPlugin('LegendExtraHeightSpacing', false)
						.setScalesAxesProp('y', 'gridLines', 'display', false)
						.setScalesAxesProp('y', 'ticks', 'display', false)
						break
					case 7:
						options
						.setLayoutPadding('right', 40)
						.setPlugin('LegendExtraHeightSpacing', false)
						break
					default:
						options.setScalesAxesProp('y', 'ticks', 'beginAtZero', true)
				}

				// Asignar la instancia de las opciones.
				chart.options = options
			}
		},

		_initDataTableChartsConfig: function() {
			this._dataTableChart1.setStates<DataTableRef['states']>({ showRefreshButton: false })
		},

		_initComponents: function() {
			this._initDataTable(this._menuBar.states.selected)
			this._fetchOperators()
		},

		_initDataTable: async function(key: number) {
			// Impedir continuar con el proceso si aun no se ha definido una 'key' valida.
			if (isNaN(key)) return

			// Algunas opciones no requieren de este proceso.
			if (key === Module4.M40.Defaults.MenuBarKeyOptions.SETTING_AUDIT_CODE) return

			// Limpiar el filtro de la tabla.
			const { selected } = this._sidePanel.states
			const { stacked } = this._dataTable.states
			this._dataTable.clearSortBy()

			// Configurar ciertas propiedades según 'Pestaña' seleccionada.
			if (selected?._id) {
				// Especificar distintos valores según el parametro 'key'.
				let fetchKey = null, params = null

				// Parametros de los KPI's.
				const { chartFilter } = this.states
				const options = { group: chartFilter.group, period: chartFilter.period }

				// CheckList Actual.
				if (key === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES) {
					fetchKey = 'Equipments'
					params = 1
					const dataTableParser = stacked ? parseStackedEquipmentsResponse : parseEquipmentsResponse
					this.setStates<View40Ref['states']>({ dataTableParser, showKPIProgressIndicator: false })
					this._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: false })
				}
				// Formularios.
				else if (key === Module4.M40.Defaults.MenuBarKeyOptions.ADDONS) {
					fetchKey = 'Addons'
					const dataTableParser = stacked ? parseStackedAddonsResponse : parseAddonsResponse
					this.setStates<View40Ref['states']>({ dataTableParser, showKPIProgressIndicator: false })
					this._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: true })
				}
				// KPI's.
				else if (key === Module4.M40.Defaults.MenuBarKeyOptions.KPI_CHARTS || key === Module4.M40.Defaults.MenuBarKeyOptions.KPI_OPERATORS) {
					fetchKey = 'KPIs'
					params = chartFilter.period
					const dataTableParser = stacked ? parseStackedCheckListsResponse : parseCheckListsResponse
					this.setStates<View40Ref['states']>({ dataTableParser, showKPIProgressIndicator: true })
					this._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: true })
				}
				// Historico (Y otras opciones).
				else {
					fetchKey = 'CheckLists'
					params = 1
					const dataTableParser = stacked ? parseStackedCheckListsResponse : parseCheckListsResponse
					this.setStates<View40Ref['states']>({ dataTableParser, showKPIProgressIndicator: false })
					this._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: false })
					this._dataTable.sortOrder('date', 'desc')
				}

				// Realizar la petición hacia el servidor.
				const response = await (this as any)[`_fetch${ fetchKey }`](selected._id, params)
				const responseLength = Array.isArray(response) ? response.length > 0 : response.data.length > 0

				// Coincide la pestaña seleccionada o se realizó una petición y se obtuvieron datos.
				if ((key === Module4.M40.Defaults.MenuBarKeyOptions.ADDONS) || (responseLength)) {
					const data = Array.isArray(response) ? response : response.data
					const { fields, items, actions } = this.states.dataTableParser(data)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)

					// Paginación para Pestaña 'Historico'.
					if (key === Module4.M40.Defaults.MenuBarKeyOptions.CHECKLISTS) {
						Store.commit('storeCheckListsForPage', { data, page: 1, totalPages: response.totalPages })
					}
					
					// El número de áctivos no debe cambiar entre las pestañas.
					if (key === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES) {
						this._sidePanel.setStates<SidePanelRef['states']>({ numActives: items?.length || 0 })
						Store.commit('storeEquipmentsForPage', { data, page: 1, totalPages: response.totalPages })
					}

					// Ejecutar solo si se presionó la pestaña de KPI's.
					if (key === Module4.M40.Defaults.MenuBarKeyOptions.KPI_CHARTS) {
						this._setChartsData(items, options)
						this.setStates<View40Ref['states']>({ showKPICharts: true, showKPIOperators: false, showKPIOptions: true, showKPIProgressIndicator: false })
					}

					// Ejecutar solo si se presionó la pestaña de KPI's Operador.
					else if (key === Module4.M40.Defaults.MenuBarKeyOptions.KPI_OPERATORS) {
						getChartGeneratorDataTable(1, stacked)(this._dataTableChart1, items, options)
						this.setStates<View40Ref['states']>({ showKPICharts: false, showKPIOperators: true, showKPIOptions: true, showKPIProgressIndicator: false })
					}
				}
				else {
					this._setChartsData()
					this._dataTable.clearData()
					this._sidePanel.setStates<SidePanelRef['states']>({ numActives: 0 })
					this.setStates<View40Ref['states']>({ showKPICharts: false, showKPIOperators: false, showKPIProgressIndicator: false })
				}
			}
		},

		_initPermissions: function() {
			this._dataTable.setPermission('ACTION_PDF', true)
			this._dataTable.setPermission('ACTION_STATUS', true)
			this._dataTable.setPermission('ACTION_VIEW', true)
		},

		_initSocketEvents: function() {
			// Conexión del Cliente del SocketIO.
			const socket: Socket = Store.getters.getStoredSocket

			// Evento Socket cuando un CheckList es Creado.
			socket.on(Module4.M40.Socket.Events.CREATED, (payload: any) => {
				// Obtener información de la Ubicación en la cual fue creado el CheckList.
				const storage = Store.getters.getStoredStorageById(payload._idStorage)
				this.onDTRefreshButtonClick()

				// Notificar al Usuario.
				const forStorage = storage ? ` asociado a Ubicación ${ storage.code }.` : '.'
				const message = `${ payload.operator } ha creado un CheckList con Código Equipo '${ payload.codeEquipment }'${ forStorage }`
				this.showToast('CheckList Creado', message, 'success')
			})
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Cambios que afectan el aspecto visual de los Componentes (según Breakpoint).
			this._dataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.XLarge })
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
			this._menuBar.setItems(Module4.M40.Collections.MenuBarOptions(breakpoint <= Breakpoints.Large))
			this._menuBar.setStates<MenuBarRef['states']>({ isMobile: breakpoint <= Breakpoints.Large })
			this._sidePanel.setStates<SidePanelRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })

			// Cambios que afectan el aspecto visual de los Graficos (según Breakpoint).
			const breakpointCharts = breakpoint <= Breakpoints.Medium
			this._updateChartsResponsiveDimension(breakpointCharts ? 0 : 280, breakpointCharts ? 280 : 400)

			// Cambios que afectan el aspecto visual de la Vista (según Breakpoint).
			this.setStates<View40Ref['states']>({ chartPieDivision: breakpointCharts ? '1' : '2' })
			this._initComponents()
		},

		_setChartsData: function(items: Array<any> = [], options: any = {}) {
			const gcb = this._getChartBasic, gc = this._getChart
			getChartGeneratorData(1)(gcb(1), gc(1), items, options)
			getChartGeneratorData(3)(gcb(3), gc(3), items, options)
			getChartGeneratorData(4)(gcb(4), gc(4), items, options)
			getChartGeneratorData(6)(gcb(6), gc(6), items, options)
			getChartGeneratorData(7)(gcb(7), gc(7), items, options)
		},

		_updateChartsResponsiveDimension: function(width: number, height: number) {
			const gcb = this._getChartBasic
			gcb(1).setStates<ChartBasicRef['states']>({ customHeight: height, widthSidePanel: width })
			gcb(3).setStates<ChartBasicRef['states']>({ customHeight: height, widthSidePanel: width })
			gcb(4).setStates<ChartBasicRef['states']>({ customHeight: height, widthSidePanel: width })
			gcb(6).setStates<ChartBasicRef['states']>({ customHeight: height, widthSidePanel: width })
			gcb(7).setStates<ChartBasicRef['states']>({ customHeight: height, widthSidePanel: width })
		},

		_updateLatestCompanyAuditCode: function() {
			const user = Store.getters.getStoredUser
			const settings = Store.getters.getStoredCheckListSettingByIdCompany(user._idCompany)
			const latestAuditCode = settings?.auditCodes?.reduce((a: any, b: any) => (new Date(a.dateUpdate).getTime() > new Date(b.dateUpdate).getTime() ? a : b))
			
			// Solo asignar si fue encontrado el registro.
			if (latestAuditCode) this._auditCodeForm.setStates<AuditCodeFormRef['states']>({ code: latestAuditCode.code, dateValidation: latestAuditCode.dateValidation })
		},

		onACFSubmitClick: async function(data: any) {
			// Datos requeridos para actualizar el Código Auditoria.
			const user = Store.getters.getStoredUser
			const { _idAdminCompany } = user
			const { _idCompany }      = user

			// Objeto con las Propiedades requeridas por la Petición.
			const body: any = {
				_idAdminCompany, _idCompany,
				_idResponsible: user._idUser,
				code: data.code,
				dateValidation: data.dateValidation
			}

			// Validación de los campos de la petición.
			const result = Module4.M40.JoiSchemas.UpsertSettingAuditCode.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, 'Error al actualizar Código Auditoria', result.error)

			// Realizar la Petición al servidor.
			const response = await this.doFetch({ action: Server.Fetching.Method.PUT, path: Server.Routes.CheckLists.PutCheckListsSettingAuditCode, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response  = response.data.body
				Store.commit('updateCheckListSetting', _response[0])
				this.showToast('Código Auditoria actualizado', 'El Código Auditoria ha sido actualizado correctamente!', 'success')
			}
		},

		onALCloseClick: function() {
			this.setStates<View40Ref['states']>({ showAttributesList: false })
		},

		onChartFilterOptionChanged: function() {
			const { selected } = this._menuBar.states
			this._setChartsData()
			switch (selected) {
				case Module4.M40.Defaults.MenuBarKeyOptions.KPI_CHARTS:
				case Module4.M40.Defaults.MenuBarKeyOptions.KPI_OPERATORS:
					return this._initDataTable(selected)
			}
		},

		onDTButtonClick: function(key: string, row: any) {
			const { selected } = this._menuBar.states
			const { item } = row

			if (key === 'view') {
				const { stacked } = this._dataTable.states
				if (selected === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES) {
					if (selected === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES && !(item.checkList)) {
						return this.showToast('Sin CheckList', 'El equipo no tiene ningun CheckList asociado!', 'danger')
					}

					// Dar formato a la Fecha.
					const { checkList } = item
					const _date = PrimitiveTools.Dates.parseDateString(checkList.date)
					const date = _date ? `${ _date.date } ${ _date.time }` : ''
					
					this._attributesList.setStates<AttributesListRef['states']>({
						type: 'actives', options: checkList.attributes,
						data: {
							date,
							active: stacked ? item.code : `${ item.code } - ${ item.groupArticle } (${ item.typeEquipment })`,
							hourmeter: checkList.hourmeter,
							comment: checkList.comment || 'Sin comentario...'
						}
					})
				}
				else {
					this._attributesList.setStates<AttributesListRef['states']>({
						type: 'actives', options: item.attributes,
						data: {
							date: item.date,
							active: stacked ? item.codeEquipment : `${ item.codeEquipment } - ${ item.groupArticle } (${ item.addonsCode })`,
							hourmeter: item.hourmeter,
							comment: item.comment || 'Sin comentario...'
						}
					})
				}
				this.setStates<View40Ref['states']>({ showAttributesList: true })
			}
			else if (key === 'pdf') {
				if (selected === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES && !(item.checkList)) {
					return this.showToast('Sin CheckList', 'El equipo no tiene ningun CheckList asociado!', 'danger')
				}
				if (selected === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES || selected === Module4.M40.Defaults.MenuBarKeyOptions.CHECKLISTS) {
					// Definición de las Columnas.
					const cols: Array<PDFTableColumn> = [
						{ key: 'num', label: 'Num' },
						{ key: 'attribute', label: 'Preguntas' },
						{ key: 'status', label: 'Estado' }
					]

					// La propiedad 'attributes' cambia según la pestaña activa.
					const attributes = selected === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES ? item.checkList.attributes : item.attributes

					// Definición de las Filas.
					let rowCounter = 1
					const rows: Array<any> = attributes.map((x: any) => ({ num: rowCounter++, attribute: x.name, status: x.status }))

					// Obtener la información correspondiente al Código Auditoria.
					this._updateLatestCompanyAuditCode()
					const auditCode = this._auditCodeForm.states.code
					const auditDate = PrimitiveTools.Dates.reverseDate(this._auditCodeForm.states.dateValidation)

					// Finalmente, generar el documento PDF.
					const { company } = this._sidePanel.states
					const _item = { ...item, auditCode, auditDate, company, storage: this._sidePanel.states.selected.name }
					generatePDF(cols, rows, _item)
				}
			}
		},

		onDTPaginationChanged: async function(page: number) {
			// La Pestaña actual activa.
			const menuBarKey = this._menuBar.states.selected

			// Realizar la petición hacia el servidor.
			if (menuBarKey === Module4.M40.Defaults.MenuBarKeyOptions.CHECKLISTS) {
				const response = await this._fetchCheckLists(this._sidePanel.states.selected._id, page)

				const data = Array.isArray(response) ? response : response.data
				const { fields, items, actions } = this.states.dataTableParser(data)
				this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
				Store.commit('storeCheckListsForPage', { data, page, totalPages: response.totalPages })
			}
			
			// Realizar la petición hacia el servidor.
			if (menuBarKey === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES) {
				const response = await this._fetchEquipments(this._sidePanel.states.selected._id, page)

				const data = Array.isArray(response) ? response : response.data
				const { fields, items, actions } = this.states.dataTableParser(data)
				this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
				Store.commit('storeEquipmentsForPage', { data, page, totalPages: response.totalPages })
			}
		},

		onDTRefreshButtonClick: function() {
			// Obtener la página en la que se encuentra el Usuario.
			const { currentPage } = this._dataTable

			// Eliminar la propiedad del Store que almacena los datos para esa página.
			Store.commit('destroyCheckListsForPage', currentPage)

			// Finalmente realizar la petición de la página actual.
			this.onDTPaginationChanged(currentPage)
		},

		onDTSearchButtonClicked: function(searching: any) {
			const { searchKey, searchValue } = searching
			const menuBarSelected = this._menuBar.states?.selected
			const _idStorage = this._sidePanel.states?.selected?._id

			// Asignar los parametros por pestaña.
			if (menuBarSelected === Module4.M40.Defaults.MenuBarKeyOptions.ACTIVES) {
				this._dataTable.doInputSearch(Server.Routes.CheckLists.GetEquipmentCheckListsForStorageByFilter,
					this.states.dataTableParser,
					{ _idStorage, searchKey, searchValue }
				)
			}
			else if (menuBarSelected === Module4.M40.Defaults.MenuBarKeyOptions.CHECKLISTS) {
				this._dataTable.doInputSearch(Server.Routes.CheckLists.GetCheckListsBySearchFilter,
					this.states.dataTableParser,
					{ _idStorage, searchKey, searchValue }
				)
			}
		},

		onMBItemClick: function(key: number) {
			// Variables para actualizar las Propiedades de Control.
			let showAuditCodeForm = false
			let showDataTable = false
			let showKPIs = false
			let showKPIsFiltersBar = false

			// Cual opción fue seleccionada.
			switch (key) {
				case Module4.M40.Defaults.MenuBarKeyOptions.KPI_CHARTS:
				case Module4.M40.Defaults.MenuBarKeyOptions.KPI_OPERATORS:
					// Ocultar todo, los Graficos serán visualizados en un proceso posterior.
					showAuditCodeForm = showDataTable = showKPIs = false
					showKPIsFiltersBar = true
					break
				case Module4.M40.Defaults.MenuBarKeyOptions.SETTING_AUDIT_CODE:
					// Actualizar el titulo del apartado de 'Configuraciones'.
					this.setStates<View40Ref['states']>({ settingTitleText: 'Código de Auditoria para Documentos PDF de CheckLists' })

					// Visualizar componente principal.
					showAuditCodeForm = true

					// Autocompletar el Formulario con el ultimo Código Auditoria.
					this._updateLatestCompanyAuditCode()

					// Ocultar el resto de componentes.
					showDataTable = showKPIs = false
					break
				default:
					// Visualizar componente principal.
					showDataTable = true

					// Ocultar el resto de componentes.
					showAuditCodeForm = showKPIs = false
					break
			}

			// Actualizar las Propiedades de Control.
			this.setStates<View40Ref['states']>({
				showAuditCodeForm,
				showDataTable,
				showKPICharts: showKPIs,
				showKPIOperators: showKPIs,
				showKPIOptions: showKPIsFiltersBar
			})

			// Vaciar la Tabla antes de ejecutar la petición correspondiente.
			this._dataTable.clearAll()
			this._initDataTable(key)
		},

		onSPItemClick: function() {
			this._initComponents()
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, ['_checkSystemAccess', '_initPermissions']),
		...VuexTools.watchStoreProperty(Vuex.Modules.Systems.Names.Module4_Addons, '_initComponents')
	}
})

// Exports
export default View40
export type View40Ref = InstanceType<typeof View40>