// ./
import { PDF } from './PDF'

// Classes
import { Vector4 } from '@/Classes/Vectors/Vector4'

// Class Export
export class PDFGeometry extends Vector4 {
	private align: GeometryValidAlignTypes = 'left'
	private color: string
	private fill = 'FD'
	private includeHeight = true
	private gap = 0
	private lineWidth = 1
	private pdf: PDF
	private radius = 0
	private strokeColor: string

	constructor(pdf: PDF, x: number, y: number, w: number, h: number, c: string) {
		super(x, y, w, h)
		this.pdf = pdf
		this.color = c
	}

	public getAlign() {
		return this.align
	}

	public getColor() {
		return this.color
	}

	public getFill() {
		return this.fill
	}

	public getGap() {
		return this.gap
	}

	public getLineWidth() {
		return this.lineWidth
	}

	public getPDF() {
		return this.pdf
	}

	public getRadius() {
		return this.radius
	}

	public getStrokeColor() {
		return this.strokeColor
	}

	public mustIncludeHeight() {
		return this.includeHeight
	}

	public setAlign(a: GeometryValidAlignTypes) {
		this.align = a
		return this
	}

	public setColor(c: string) {
		this.color = c
		return this
	}

	public setFill(f: string) {
		this.fill = f
		return this
	}

	public setGap(n: number) {
		this.gap = n
		return this
	}

	public setLineWidth(n: number) {
		this.lineWidth = n
		return this
	}

	public setRadius(r: number) {
		this.radius = r
		return this
	}

	public setStrokeColor(c: string) {
		this.strokeColor = c
		return this
	}

	public setIncludeHeight(b: boolean) {
		this.includeHeight = b
		return this
	}
}

export class PDFRectangle extends PDFGeometry {
	constructor(pdf: PDF, x: number, y: number, w: number, h: number, c = 'black') {
		super(pdf, x, y, w, h, c)
	}

	public draw(x?: number, y?: number, w?: number, h?: number) {
		const pdf = this.getPDF()

		// Convertir los tamaños a milimetros.
		const _w = w || this.getZ()
		const _h = h || this.getW()
		const _r = this.getRadius()

		// Dibujar la forma geometrica.
		const _x = x || pdf.getGlobalX() + this.getX()
		const _y = y || pdf.getGlobalY() + this.getY()

		pdf.getGlobalDoc().setLineWidth(this.getLineWidth())
		pdf.setFillColor(this.getColor())
		pdf.setStrokeColor(this.getStrokeColor() || this.getColor())
		pdf.getGlobalDoc().roundedRect(_x, _y, _w, _h, _r, 0, this.getFill())

		// Actualizar Coordenadas en el objeto PDF principal.
		pdf.updateGlobalY(this.getY() + (this.mustIncludeHeight() ? this.getW() : 0))

		// Actualizar el Espacio Global disponible de la página.
		pdf.updateAvailableHeightSpace(_h)
	}
}

export class PDFRoundedRectangle extends PDFGeometry {
	private corners: RectangleRoundedCornersType

	constructor(pdf: PDF, x: number, y: number, w: number, h: number, r: number, corners: RectangleRoundedCornersType, c = 'black') {
		super(pdf, x, y, w, h, c)
		this.setRadius(r)
		this.corners = corners
	}

	public draw(x?: number, y?: number, w?: number, h?: number) {
		const pdf = this.getPDF()

		// Convertir los tamaños a milimetros.
		const _w = w || this.getZ()
		const _h = h || this.getW()

		// Convertir tambien los rectangulos que redonderán las esquinas.
		const _r = this.getRadius()

		// Instancia del objeto 'jsPDF'.
		const doc = pdf.getGlobalDoc()

		// Dibujar la forma geometrica.
		const _x = x || pdf.getGlobalX() + this.getX()
		const _y = y || pdf.getGlobalY() + this.getY()

		// Segun la propiedad 'corners', se especificará cuales esquinas serán redondeadas.
		const corners = this.corners
		const _c1 = corners[0] === 1
		const _c2 = corners[1] === 1
		const _c3 = corners[2] === 1
		const _c4 = corners[3] === 1

		pdf.setFillColor(this.getColor())
		pdf.setStrokeColor(this.getStrokeColor() || this.getColor())

		// Base Superior, Derecha, Inferior, Izquierda, Centro
		doc.rect(_x + _r / 2, _y, _w - _r, _r, this.getFill())
		doc.rect(_x + _w - _r, _y + _r / 2, _r, _h - _r, this.getFill())
		doc.rect(_x + _r / 2, _y + _h - _r, _w - _r, _r, this.getFill())
		doc.rect(_x, _y + _r / 2, _r, _h - _r, this.getFill())
		doc.rect(_x + _r / 2, _y + _r / 2, _w - _r, _h - _r, this.getFill())

		// Esquina SupIzq, SupDer, InfDer, InfIzq
		const cTopLeft = _c1 ? _r : 0
		const cTopRight = _c2 ? _r : 0
		const cBottomRight = _c3 ? _r : 0
		const cBottomLeft = _c4 ? _r : 0
		doc.roundedRect(_x, _y, _r, _r, cTopLeft, cTopLeft, this.getFill())
		doc.roundedRect(_x + _w - _r, _y, _r, _r, cTopRight, cTopRight, this.getFill())
		doc.roundedRect(_x + _w - _r, _y + _h - _r, _r, _r, cBottomRight, cBottomRight, this.getFill())
		doc.roundedRect(_x, _y + _h - _r, _r, _r, cBottomLeft, cBottomLeft, this.getFill())

		// Actualizar Coordenadas en el objeto PDF principal.
		pdf.updateGlobalY(this.getY() + (this.mustIncludeHeight() ? this.getW() : 0))
	}
}

type GeometryValidAlignTypes = 'left' | 'center' | 'right' | 'top' | 'bottom'
type RectangleRoundedCornersType = [0|1, 0|1, 0|1, 0|1]