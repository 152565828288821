// Dependencies
import Vue from 'vue'
import { HorizontalBar, mixins } from 'vue-chartjs'

// Chart Export
export default Vue.extend({
	name: 'ChartHorizontalBarComponent',
	extends: HorizontalBar,
	mixins: [mixins.reactiveProp],
	props: ['options'],

	mounted: function() {
		this.render()
	},

	methods: {
		render: function() {
			const renderChart: any = this.renderChart
			renderChart(this.chartData, this.options)
		}
	}
})