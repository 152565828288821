// ./
import { ChartLabel } from './ChartLabel'

export class ChartData<DataType, DataSet = ChartDataSet<DataType> | ChartPieDataSet<DataType>> {
	private datasets: Array<DataSet> = []
	private labels: Array<string> = []

	public addDataSet(dataSet: DataSet) {
		this.datasets.push(dataSet)
		return this
	}

	public clearDataSets() {
		this.datasets = []
		return this
	}

	public setLabels(labels: Array<string>) {
		this.labels = labels
		return this
	}
}

class ChartDataSetStyleProperties {
	protected backgroundColor?: string
	protected borderColor?: string
	protected borderWidth?: number | object
	protected lineTension?: number
	protected fill?: boolean

	public setBackgroundColor(c: string) {
		this.backgroundColor = c
		return this
	}
	
	public setBarColor(c: string) {
		this.setBackgroundColor(c)
		this.setBorderColor(c)
		return this
	}

	public setBorderColor(c: string) {
		this.borderColor = c
		return this
	}

	public setBorderWidth(w: number | object) {
		this.borderWidth = w
		return this
	}

	public setFill(f: boolean) {
		this.fill = f
		return this
	}

	public setLineTension(n: number) {
		this.lineTension = n
		return this
	}
}

export class ChartDataSet<DataType> extends ChartDataSetStyleProperties {
	private label?: string
	private data?: Array<DataType>
	private hidden?: boolean

	constructor(label?: string, data?: Array<DataType>) {
		super()
		if (label) this.setLabel(label)
		if (data) this.setData(data)
	}

	public setData(data: Array<DataType>) {
		this.data = data
		return this
	}

	public setHidden(hidden: boolean) {
		this.hidden = hidden
		return this
	}

	public setLabel(label: string) {
		this.label = label
		return this
	}
}

export class ChartPieDataSet<DataType> {
	private backgroundColor?: Array<string>
	private data?: Array<DataType>

	constructor(data?: Array<DataType>) {
		if (data) this.setData(data)
	}

	public setColors(c: Array<string>) {
		this.backgroundColor = c
		return this
	}

	public setData(data: Array<DataType>) {
		this.data = data
		return this
	}
}

export class ChartOptions {
	private layout: any = { padding: {} }
	private legend: any = {}
	private maintainAspectRatio?: boolean
	private plugins?: IPlugins = {}
	private responsive?: boolean = true
	private scales?: IScales = {}

	private _getCurrentScalesAxes(axis: Axis) {
		return this.scales[this._getScalesAxesName(axis)][0]
	}

	private _getScalesAxesName(axis: Axis) {
		return `${ axis }Axes`
	}

	private _initScalesAxes(axis: Axis) {
		if (!Array.isArray(this.scales[this._getScalesAxesName(axis)])) {
			this.scales[this._getScalesAxesName(axis)] = []
		}
	}

	private _initScalesAxesProp(axis: Axis, propName: string) {
		const axes = this._getCurrentScalesAxes(axis)
		if (axes[propName] === undefined) axes[propName] = {}
	}

	public setLayoutPadding(keyOrValue: number | string, value?: number) {
		if (typeof keyOrValue === 'number') {
			this.layout.padding.top    = keyOrValue
			this.layout.padding.right  = keyOrValue
			this.layout.padding.bottom = keyOrValue
			this.layout.padding.left   = keyOrValue
			return this
		}
		this.layout.padding[keyOrValue] = value
		return this
	}

	public setLegendProp(propName: string, value: any) {
		this.legend[propName] = value
		return this
	}

	public setMaintainAspectRatio(maintain: boolean) {
		this.maintainAspectRatio = maintain
		return this
	}

	public setPlugin(id: string, plugin: ChartLabel | boolean) {
		this.plugins[id] = plugin
		return this
	}

	public setResponsive(responsive: boolean) {
		this.responsive = responsive
		return this
	}

	public setScalesAxesProp(axis: Axis, mainProp: 'gridLines' | 'ticks', subProp: string, value: any) {
		this._initScalesAxes(axis)
		const axes = this._getCurrentScalesAxes(axis)
		if (axes) {
			this._initScalesAxesProp(axis, mainProp)
			axes[mainProp][subProp] = value
		}
		else {
			const axesName = this._getScalesAxesName(axis)
			this.scales[axesName].push({ [mainProp]: { [subProp]: value } })
		}
		return this
	}
}

// Class::ChartOptions Interfaces
interface IPlugins {
	[key: string]: any
	datalabels?: ChartLabel
}

interface IScales {
	[key: string]: Array<IScalesAxes>
	xAxes?: Array<IScalesAxes>
	yAxes?: Array<IScalesAxes>
}

interface IScalesAxes {
	[key: string]: IScalesAxesGridLines | IScalesAxesTicks
	gridLines?: IScalesAxesGridLines
	ticks?: IScalesAxesTicks
}

interface IScalesAxesGridLines {
	[key: string]: boolean | number
	display?: boolean
	tickMarkLength?: number
}

interface IScalesAxesTicks {
	[key: string]: boolean | number
	beginAtZero?: boolean
	max?: number
	min?: number
}

// Class::ChartOptions Types
type Axis = 'x' | 'y'