export class Vector4 {
	private x: number
	private y: number
	private z: number
	private w: number

	constructor(x: number, y: number, z: number, w: number) {
		this.x = x
		this.y = y
		this.z = z
		this.w = w
	}

	public getX() {
		return this.x
	}

	public getY() {
		return this.y
	}

	public getW() {
		return this.w
	}

	public getZ() {
		return this.z
	}

	public setX(x: number) {
		this.x = x
	}

	public setY(y: number) {
		this.y = y
	}

	public setW(w: number) {
		this.w = w
	}

	public setZ(z: number) {
		this.z = z
	}
}