export class PDFElement {
	private align: TextValidAlignTypes = 'left'
	private gap = 0
	private offset: number

	constructor(offset: number) {
		this.offset = offset
	}
	
	public getAlign() {
		return this.align
	}

	public getGap() {
		return this.gap
	}

	public getOffset() {
		return this.offset
	}

	public setAlign(a: TextValidAlignTypes) {
		this.align = a
		return this
	}

	public setGap(n: number) {
		this.gap = n
		return this
	}
}
export class PDFImage extends PDFElement {
	private w: number
	private h: number
	private src: string

	constructor(offset: number, w: number, h: number, src: string) {
		super(offset)
		this.w = w
		this.h = h
		this.src = src
	}

	public getHeight() {
		return this.h
	}

	public getSource() {
		return this.src
	}

	public getWidth() {
		return this.w
	}
}

export class PDFText extends PDFElement {
	private color: string
	private fontName = 'OpenSans-Normal'
	private fontStyle = 'normal'
	private fontSize: number
	private text: string
	
	constructor(text: string, offset = 0, fontSize = 0, color = 'black') {
		super(offset)
		this.color = color
		this.fontSize = fontSize
		this.text = text
	}

	public getColor() {
		return this.color
	}

	public getFont() {
		return { font: this.fontName, style: this.fontStyle }
	}

	public getFontSize() {
		return this.fontSize
	}

	public getText() {
		return this.text
	}

	public setFont(fontName: string, style?: string) {
		this.fontName = fontName
		if (style) this.setStyle(style)
		return this
	}

	public setStyle(fontStyle: string) {
		this.fontStyle = fontStyle
		return this
	}
}

type TextValidAlignTypes = 'left' | 'center' | 'right' | 'top' | 'bottom'