// Classes
import { PDF }                      from '@/Classes/Files/pdf/PDF'
import { PDFFooter, PDFHeader }     from '@/Classes/Files/pdf/PDFComponents'
import { PDFContainer }             from '@/Classes/Files/pdf/PDFContainer'
import { PDFText }                  from '@/Classes/Files/pdf/PDFElements'
import { PDFRectangle }             from '@/Classes/Files/pdf/PDFGeometry'
import { PDFTable, PDFTableColumn } from '@/Classes/Files/pdf/PDFTable'
import { PDFTableExtraMarginOnNewPageCreation, PDFTableSetSpecificBodyCellStyle } from '@/Classes/Files/pdf/PDFTable'
import { PDFUtils }                 from '@/Classes/Files/pdf/PDFUtils'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'

function _addCheckListNumber(pdf: PDF, code: string) {
	pdf.setFont('OpenSans-Bold').setFontColor('#314867').setFontSize(10)
	pdf.addText(`N° Registro: ${ code }`, 0, 16, 0, true)
}

function _getCompanyLogo() {
	return require('@/Resources/Global/Images/Brands/Kreis.png')
}

export function generatePDF(cols: Array<PDFTableColumn>, rows: Array<any>, item: any) {
	// Instancia de la clase PDF.
	const pdf = new PDF({}, { drawHeaderSafeArea: false, drawFooterSafeArea: false }).create()

	// Otras Instancias relacionadas a PDF.
	const utils = new PDFUtils()

	// La ubicación de las propiedades cambia según la pestaña de la vista.
	const _idCheckList   = item._idChecklist                || item?.checkList._id        || 'N/A'
	const auditCode      = item?.dataSetting?.auditCode     || null
	const auditDate      = item?.dataSetting?.auditDate     || null
	const comment        = item?.comment                    || item?.checkList?.comment   || 'N/A'
	const hourmeter      = item?.hourmeter                  || item?.checkList?.hourmeter || null
	const codeEquipment  = item.codeEquipment               || item.code                  || 'N/A'
	const date           = item.date                        || item.dateCheckList         || 'N/A'

	// Header
	new PDFHeader(pdf, 'Formulario', _getCompanyLogo())

	// SubHeader.
	utils.addSubHeaderAndInfoToRight(pdf, 'CheckList', auditCode ? `Cód. Auditoria: ${ auditCode }` : '', auditDate ? `Fecha Vigencia: ${ auditDate }` : '')

	// Numero de Registro.
	_addCheckListNumber(pdf, _idCheckList)

	// Tabla (Izquierda) con la información del Cliente y Ubicación.
	utils.addTableClientInformation(pdf, item.company, item.storage)

	// Tabla (Derecha) con la información de la Fecha y Responsable.
	utils.addTableDateResponsibleInformation(pdf, date, item.operator)

	// Información General del Registro.
	new PDFRectangle(pdf, 0, 16, 8, 44, '#FD921A').setIncludeHeight(false).draw()

	// Valor del Horómetro (si aplica).
	const _hourmeter = hourmeter && hourmeter !== AppValues.Strings.DEFAULT_EMPTY_STRING ? `${ hourmeter } Hrs.` : 'No aplica'
	new PDFContainer(pdf, 9, 0, 'F', 'A')
		.setPadding(8)
		.addElement(new PDFText(`Activo: ${ codeEquipment } ${ item?.groupArticle ? ` - ${ item?.groupArticle }` : '' }`, 0, 16, '#127B2D').setFont('OpenSans-Bold').setGap(4))
		.addElement(new PDFText(`Horómetro: ${ _hourmeter }`, 0, 10, '#2c4d35'))
		.draw()
	;

	// Tabla Principal del Documento PDF.
	const tableData = new PDFTable(pdf, 0, 16, cols, rows)

	// Agregar una Callback que ajustará la posición de la tabla en la nueva pagina (si es creada).
	tableData
		.setMargin('bottom', 0)
		.onCellBeforeDraw((data) => {
			//	Ajustar la posición de la tabla (en nuevas páginas).
			PDFTableExtraMarginOnNewPageCreation(pdf, data, 'num', 16)

			// Ajustar la alineación de columnas especificas.
			PDFTableSetSpecificBodyCellStyle(data, 'num', 'halign', 'center')
			PDFTableSetSpecificBodyCellStyle(data, 'status', 'halign', 'center')

			// Agregar una Imagen en cada Celda de una Columna.
			if (data.section === 'body' && data.column.dataKey === 'status') {
				const cellHeight = data.cell.height - data.cell.padding('vertical')
				const textPos = data.cell.getTextPos()
				const checkIcon = new Image().src = require('@/Resources/Global/Icons/FontAwesome/check-circle-solid.png')
				const timesIcon = new Image().src = require('@/Resources/Global/Icons/FontAwesome/times-circle-solid.png')
				const image = data.cell.text[0] === 'true' ? checkIcon : timesIcon
				pdf.getGlobalDoc().addImage(image, 'PNG', textPos.x - (12 * .5), textPos.y - (cellHeight * .5), 12, cellHeight)
				data.cell.text[0] = ''
			}
		})
		.draw()
	;

	// Incluir el Comentario despues de la Tabla.
	new PDFContainer(pdf, 0, 16, 'F', 80).setBorderColor('#DDDDDD').setPadding(8)
		.addElement(new PDFText('Comentario:', 0, 10, '#D36900').setFont('OpenSans-Bold').setGap(4))
		.addElement(new PDFText(comment, 0, 10))
		.draw()
	;

	// Footer
	new PDFFooter(pdf, 'KREIS SpA', 'Av. Pdte Jorge Alessandri 11832 - San Bernardo - Chile')

	// El numero de páginas debe ser lo ultimo que se agregue al documento PDF.
	pdf.addNumberOfPages()

	// Finalmente, abrir el archivo PDF en una nueva pestaña.
	pdf.open('Informe CheckList')
}