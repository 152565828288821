import { render, staticRenderFns } from "./template.vue?vue&type=template&id=32075476&scoped=true&"
import script from "@/Components/Extras/ChartBasic/component.ts?vue&type=script&lang=ts&"
export * from "@/Components/Extras/ChartBasic/component.ts?vue&type=script&lang=ts&"
import style0 from "@/Components/Extras/ChartBasic/style.scss?vue&type=style&index=0&id=32075476&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32075476",
  null
  
)

export default component.exports