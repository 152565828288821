// Class Export
export class ChartLabel {
	private align?: string
	private anchor?: ('center' | 'start' | 'end')
	private backgroundColor?: string
	private borderRadius?: number
	private color?: string
	private display?: boolean
	private font?: IFont = {}
	private formatter?: Function
	private offset = 4
	private padding?: IPadding = {}

	public setAlign(align: string) {
		this.align = align
		return this
	}

	public setAnchor(anchor: ('center' | 'start' | 'end')) {
		this.anchor = anchor
		return this
	}

	public setBackgroundColor(c: string) {
		this.backgroundColor = c
		return this
	}

	public setBorderRadius(radius: number) {
		this.borderRadius = radius
		return this
	}

	public setColor(c: string) {
		this.color = c
		return this
	}

	public setDisplay(display: boolean) {
		this.display = display
		return this
	}

	public setFontFamily(family: string) {
		this.font.family = family
		return this
	}
	
	public setFontSize(size: number) {
		this.font.size = size
		return this
	}

	public setFontWeight(weight: string) {
		this.font.weight = weight
		return this
	}

	public setFormatter(formatter: Function) {
		this.formatter = formatter
		return this
	}

	public setOffset(offset: number) {
		this.offset = offset
		return this
	}

	public setPadding(keyOrValue: number | string, value?: number) {
		if (typeof keyOrValue === 'number') {
			this.padding.top    = keyOrValue
			this.padding.right  = keyOrValue
			this.padding.bottom = keyOrValue
			this.padding.left   = keyOrValue
			return this
		}
		this.padding[keyOrValue] = value
		return this
	}
}

// Module Interfaces
interface IFont {
	family?: string
	lineHeight?: string
	size?: number
	style?: string
	weight?: string
}

interface IPadding {
	[key: string]: number
	top?: number
	right?: number
	bottom?: number
	left?: number
}