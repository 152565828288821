// ./
import { PDF } from './PDF'
import { PDFRectangle } from './PDFGeometry'

// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Dependencies
import autoTable, { Color, RowInput } from 'jspdf-autotable'
import { CellHookData, jsPDFDocument, Pos, Settings, Table, UserOptions } from 'jspdf-autotable'

// Class Export
export class PDFTable {
	// Propiedades internas de la clase 'PDFTable'.
	private pdf: PDF
	private activeColId: string
	private cols: Array<PDFTableColumn> = []
	private footerX = 0
	private footerY = 0
	private headStyles: Array<{ style: string, value: any }> = []
	private rows: Array<PDFTableRow> = []
	private updateGlobalY = true
	private x: number
	private y: number

	// Propiedades Generales.
	private tableOptions: UserOptions = {
		bodyStyles: { valign: 'middle' },
		columnStyles: {},
		headStyles: { valign: 'middle' },
		margin: { top: 28, right: 0, bottom: 0, left: 0 },
		showHead: 'everyPage',
		showFoot: 'never',
		styles: {},
		tableWidth: 'wrap',
		theme: 'plain'
	}

	constructor(pdf: PDF, x: number, y: number, cols?: Array<PDFTableColumn>, rows?: Array<PDFTableRow>, theme = 'default') {
		this.pdf = pdf
		this.x = x
		this.y = y

		// Data para generar el contenido de la tabla.
		if (cols) this.cols = cols
		if (rows) this.rows = rows

		// Incluir la posición X como el margen izquierdo.
		const options: any = this.tableOptions
		options.startY = this.pdf.getGlobalY() + this.y
		this.setMargin('left', x)

		// Establecer el Theme (estilos predeterminados).
		this._applyTableTheme(theme)
	}

	/* <=================|=============================|==================> */
	/* <=================| PRIVATE DECLARATION METHODS |==================> */
	/* <=================|=============================|==================> */

	private _afterTableDrawn() {
		this.tableOptions.didDrawPage = (data) => {
			// Actualizar la Posición Global Y.
			const tableHeight = this.getTableHeight(data.table)
			if (this.updateGlobalY) this.pdf.updateGlobalY(tableHeight)

			// Actualizar el Espacio Global disponible de la página.
			this.pdf.updateAvailableHeightSpace(tableHeight, true)
		}

		return this
	}

	private _getActiveColId() {
		return this.activeColId
	}

	private _applyTableTheme(theme: string) {
		switch (theme) {
			case 'default':
				this.setBodyStyle('lineColor', '#DDDDDD')
					.setBodyStyle('lineWidth', 1)
					.setBodyStyle('textColor', '#345A5A')
					.setHeaderStyle('fillColor', '#FBFBFB')
					.setHeaderStyle('halign', 'center')
					.setHeaderStyle('lineColor', '#DDDDDD')
					.setHeaderStyle('lineWidth', 1)
					.setHeaderStyle('textColor', '#345A5A')
					.setWidth('auto')
				; break;
		}
	}

	private _getColumnStylesById(id: string) {
		const c = this.tableOptions.columnStyles[id]
		if (!c) this.tableOptions.columnStyles[id] = {}
		return this.tableOptions.columnStyles[id]
	}

	private _getCurrentColumnStyles() {
		const id = this._getActiveColId()
		return this._getColumnStylesById(id)
	}

	private _verifyIfNewPageWasCreated() {
		const lastTable = this.pdf.getLastTable()
		if (lastTable.pageNumber !== this.pdf.getCurrentPage()) {
			this.pdf.setCurrentPage(lastTable.pageNumber)
			this.pdf.setGlobalY(this.pdf.getLastTableY())
			this.pdf.setAvailableHeightSpace(this.pdf.getPageHeight() - lastTable.finalY - 21.5)
		}
	}

	/* <==================|============================|==================> */
	/* <==================| PUBLIC DECLARATION METHODS |==================> */
	/* <==================|============================|==================> */

	public applyHeadStyles() {
		this.onCellParsed((data) => {
			if (data.section === 'head') {
				if (data.column.dataKey === this._getActiveColId()) {
					for (const h of this.headStyles) {
						const { styles }: any = data.cell
						styles[h.style] = h.value
					}
					this.headStyles = []
				}
			}
		})
		return this
	}

	public colAlign(h: HorizontalAlign, v: VerticalAlign) {
		this.colAlignH(h).colAlignV(v)
		return this
	}

	public colAlignH(align: HorizontalAlign) {
		const column = this._getCurrentColumnStyles()
		column.halign = align
		return this
	}

	public colAlignV(align: VerticalAlign) {
		const column = this._getCurrentColumnStyles()
		column.valign = align
		return this
	}

	public colFillColor(c: Color) {
		const column = this._getCurrentColumnStyles()
		column.fillColor = c
		return this
	}

	public colFontStyle(style: string) {
		const column: any = this._getCurrentColumnStyles()
		column.fontStyle = style
		return this
	}
	
	public colTextColor(c: Color) {
		const column = this._getCurrentColumnStyles()
		column.textColor = c
		return this
	}

	public colWidth(w: 'auto' | 'wrap' | number) {
		const column = this._getCurrentColumnStyles()
		column.cellWidth = w
		return this
	}

	public draw(preventDefaults = false) {
		// Función Core para generar Tablas.
		const options = this.tableOptions
		const doc = this.pdf.getGlobalDoc()

		// La data de las Columnas y Filas debe ir incluido en el mismo objeto de opciones.
		if (!preventDefaults) {
			options.body = this.rows
			options.columns = this.cols.map((c) => { return { dataKey: c.key, header: c.label } })
		}

		// Ejecutar el Hook que actualizará el GlobalY.
		this._afterTableDrawn()

		// Generar la tabla en el documento de jsPDF.
		autoTable(doc, options)

		// Actualizar la Posición Global Y.
		if (this.updateGlobalY) this.pdf.updateGlobalY(this.y)

		// Verificar si la tabla generó una nueva pagina, la posición Y debe ser restablecida.
		this._verifyIfNewPageWasCreated()
	}

	public drawFooter(pdf: PDF, text: string, align = 'right') {
		new PDFRectangle(pdf, 0, this.footerY, pdf.getPageWidth(), 24)
			.setIncludeHeight(false)
			.setColor('#FBFBFB')
			.setStrokeColor('#DDDDDD')
			.draw()
		;
		
		const baseX = (pdf.getPageWidth() - 8) + this.footerX
		pdf.updateTextOption('align', align).setFont('OpenSans-Normal').setFontColor('#345A5A')
		pdf.addText(text, baseX, 7, 10)
	}

	public getMargins() {
		const margins: any = this.tableOptions.margin
		return margins
	}

	public getPDF() {
		return this.pdf
	}

	public getTableHeight(table: Table) {
		// Variable con la posición Y que se debe incluir a la posición Global Y.
		let y = 0

		// Solamente incluir el 'header' si este se esta mostrando.
		const { showHead } = this.tableOptions
		if (showHead !== 'never') y += table.head[0].height

		// Solamente incluir el 'footer' si este se esta mostrando.
		const { showFoot } = this.tableOptions
		if (showFoot !== 'never' && table.foot.length > 0) y += table.foot[0].height

		// Incrementar la variable 'y' con el alto de cada fila.
		for (const r of table.body) y += r.height
		return y
	}

	public getX() {
		return this.x
	}

	public getY() {
		return this.y
	}

	public headAlign(h: HorizontalAlign, v: VerticalAlign) {
		this.headAlignH(h).headAlignV(v)
		return this
	}

	public headAlignH(align: HorizontalAlign) {
		this.headStyles.push({ style: 'halign', value: align })
		return this
	}

	public headAlignV(align: VerticalAlign) {
		this.headStyles.push({ style: 'valign', value: align })
		return this
	}

	public onCellBeforeDraw(callback: (hookData: CellHookData) => void) {
		// Hook: Called before a cell or row is drawn. Can be used to call native jspdf styling functions such as doc.setTextColor or change
		// position of text etc before it is drawn.
		this.tableOptions.willDrawCell = callback
		return this
	}

	public onCellDrawn(callback: (hookData: CellHookData) => void) {
		// Hook: Called after a cell has been added to the page. Can be used to draw additional cell content such as images with doc.addImage,
		// additional text with doc.addText or other jspdf shapes.
		this.tableOptions.didDrawCell = callback
		return this
	}

	public onCellParsed(callback: (hookData: CellHookData) => void) {
		// Hook: Called when the plugin finished parsing cell content. Can be used to override content or styles for a specific cell.
		this.tableOptions.didParseCell = callback
		return this
	}

	public setActiveColId(id: string) {
		this.activeColId = id
		return this
	}

	public setBody(body: Array<RowInput>) {
		this.tableOptions.body = body
		return this
	}

	public setBodyStyle(sName: string, sValue: any) {
		const bodyStyles: any = this.tableOptions.bodyStyles
		bodyStyles[sName] = sValue
		return this
	}

	public setFoot(foot: Array<RowInput>) {
		this.tableOptions.foot = foot
		return this
	}

	public setFooterX(x: number) {
		this.footerX = x
		return this
	}

	public setFooterY(y: number) {
		this.footerY = y
		return this
	}

	public setHead(head: Array<RowInput>) {
		this.tableOptions.head = head
		return this
	}

	public setHeaderStyle(sName: string, sValue: any) {
		const headStyles: any = this.tableOptions.headStyles
		headStyles[sName] = sValue
		return this
	}

	public setMargin(side: string, val: number, includePageMargin = true) {
		const options: any = this.tableOptions
		const pageMargin = this.pdf.getPageMargin()
		options.margin[side] = val + (includePageMargin ? pageMargin : 0)
		return this
	}

	public setStyle(sName: string, sValue: any) {
		const styles: any = this.tableOptions.styles
		styles[sName] = sValue
		return this
	}

	public setTheme(theme: 'striped' | 'grid' | 'plain') {
		this.tableOptions.theme = theme
		return this
	}

	public setUpdateGlobalY(bool: boolean) {
		this.updateGlobalY = bool
		return this
	}

	public setWidth(w: 'auto' | 'wrap' | number) {
		// Comprobar si el ancho es un numero.
		if (typeof w === 'number') {
			this.tableOptions.tableWidth = w
		}
		else {
			this.tableOptions.tableWidth = w === 'auto' ? this.pdf.getPageWidth() : w
		}
		return this
	}

	public setY(y: number) {
		this.tableOptions.startY = y
		return this
	}

	public showHeader(show: 'always' | 'once' | false) {
		let showHead: any = null
		switch (show) {
			case 'always': showHead = 'everyPage'; break 
			case 'once': showHead = 'firstPage'; break
			case false: showHead = 'never'; break
		}
		this.tableOptions.showHead = showHead
		return this
	}
}

// Function Export: Ajusta automaticamente la posición del segmento de la tabla creada en una nueva página.
export function PDFTableExtraMarginOnNewPageCreation(pdf: PDF, data: CellHookData, firstColumnKey: string, offset: number) {
	if (pdf.getCurrentPage() < data.pageNumber) {
		if (data.section === 'head' && data.column.dataKey === firstColumnKey) {
			data.cursor.y += offset
			data.cell.y += offset
		}
	}
}

// Function Export: Ajusta el estilo de una celda en especifica.
export function PDFTableSetSpecificBodyCellStyle(data: CellHookData, columnKey: string, styleProp: string, styleValue: any) {
	if (data.section === 'body' && data.column.dataKey === columnKey) {
		const styles: any = data.cell.styles
		styles[styleProp] = styleValue
	}
}

// Helper Class
class HookData {
	table: Table
	pageNumber: number
	pageCount: number
	settings: Settings
	doc: jsPDFDocument
	cursor: Pos | null
}

// Interface Export
export interface PDFTableColumn {
	key: string
	label: string
}

// Interface Export
export interface PDFTableRow {
	[key: string]: number | string
}

type HorizontalAlign = 'left' | 'center' | 'right'
type VerticalAlign = 'top' | 'middle' | 'bottom'